// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-x-about-js": () => import("./../../../src/pages/xAbout.js" /* webpackChunkName: "component---src-pages-x-about-js" */),
  "component---src-pages-x-forsaljning-js": () => import("./../../../src/pages/xForsaljning.js" /* webpackChunkName: "component---src-pages-x-forsaljning-js" */),
  "component---src-pages-x-hastarna-js": () => import("./../../../src/pages/xHastarna.js" /* webpackChunkName: "component---src-pages-x-hastarna-js" */),
  "component---src-pages-x-inackordering-js": () => import("./../../../src/pages/xInackordering.js" /* webpackChunkName: "component---src-pages-x-inackordering-js" */),
  "component---src-pages-x-inridning-js": () => import("./../../../src/pages/xInridning.js" /* webpackChunkName: "component---src-pages-x-inridning-js" */),
  "component---src-pages-x-losdrift-js": () => import("./../../../src/pages/xLosdrift.js" /* webpackChunkName: "component---src-pages-x-losdrift-js" */),
  "component---src-pages-x-traning-js": () => import("./../../../src/pages/xTraning.js" /* webpackChunkName: "component---src-pages-x-traning-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */)
}

